import React from 'react'
import { Link } from 'react-router-dom'

import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import './saabuvad-ritused.css'

const SaabuvadRitused = (props) => {
  return (
    <div className="saabuvad-ritused-container">
      <Helmet>
        <title>Saabuvad-ritused - Teadusklubi</title>
        <meta property="og:title" content="Saabuvad-ritused - Teadusklubi" />
      </Helmet>
      <header data-thq="thq-navbar" className="saabuvad-ritused-navbar">
        <span className="saabuvad-ritused-text">MTÜ Teadusklubi</span>
        <div
          data-thq="thq-burger-menu"
          className="saabuvad-ritused-burger-menu"
        >
          <div className="saabuvad-ritused-hamburger">
            <img
              alt="image"
              src="/playground_assets/hamburger.svg"
              className="saabuvad-ritused-icon"
            />
          </div>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="saabuvad-ritused-mobile-menu"
        >
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="saabuvad-ritused-nav"
          >
            <div className="saabuvad-ritused-container1">
              <span className="saabuvad-ritused-text01">MTÜ Teaudsklubi</span>
              <div
                data-thq="thq-close-menu"
                className="saabuvad-ritused-menu-close"
              >
                <svg viewBox="0 0 1024 1024" className="saabuvad-ritused-icon1">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="saabuvad-ritused-nav1"
            >
              <Link to="/" className="saabuvad-ritused-navlink button">
                Home
              </Link>
              <Link
                to="/saabuvad-ritused"
                className="saabuvad-ritused-navlink01 button"
              >
                Saabuvad üritused
              </Link>
              <Link
                to="/projektid"
                className="saabuvad-ritused-navlink02 button"
              >
                Projektid
              </Link>
              <Link to="/kontakt" className="saabuvad-ritused-navlink03 button">
                Kontakt
              </Link>
            </nav>
          </div>
          <div className="saabuvad-ritused-icon-group">
            <svg
              viewBox="0 0 950.8571428571428 1024"
              className="saabuvad-ritused-icon3"
            >
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="saabuvad-ritused-icon5"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg
              viewBox="0 0 602.2582857142856 1024"
              className="saabuvad-ritused-icon7"
            >
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </header>
      <div id="saabuv" className="saabuvad-ritused-features">
        <h1 className="saabuvad-ritused-text02">Saabuvad üritused</h1>
        <div className="saabuvad-ritused-separator"></div>
      </div>
      <div className="saabuvad-ritused-banner">
        <h1 className="saabuvad-ritused-heading">
          <span>
            Ray Foil first launch x
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <span>Wambola hooajaavamine</span>
        </h1>
        <h2 className="saabuvad-ritused-heading1">19. mai @ Noblessner</h2>
        <span className="saabuvad-ritused-text06">
          Teadusklubi projekt Ray Foil tähistab koos Wambola surfiklubiga 2023.
          aasta veehooaja avamist. Alates 19. maist on võimalik Wambola
          surifkeskusest rentida Ray Foile ja nendega sõita. Üritus tuleb väga
          meeleolukas ja täis elamusi.
        </span>
        <a
          href="https://rayfoil.surf"
          target="_blank"
          rel="noreferrer noopener"
          className="saabuvad-ritused-link button"
        >
          Lisainfo
        </a>
      </div>
      <div className="saabuvad-ritused-container2"></div>
      <div className="saabuvad-ritused-banner1">
        <h1 className="saabuvad-ritused-heading2">
          Let&apos;s Startup Teadusklubi!
        </h1>
        <h2 className="saabuvad-ritused-heading3">27. aprill @ TalTech</h2>
        <span className="saabuvad-ritused-text07">
          Teadusklubi korraldab 6. aprillil TalTechis tudengiorganisatsioonidele
          suunatud ürituse. Eesmräk on tutvustada teistele, mis võimalused on
          Teadusklubiga ja siduda erinevaid tudengiorganisatsioone omavahel.
        </span>
        <a
          href="https://fb.me/e/2C3XakWxU"
          target="_blank"
          rel="noreferrer noopener"
          className="saabuvad-ritused-link1 button"
        >
          Lisainfo
        </a>
      </div>
      <div className="saabuvad-ritused-footer">
        <div className="saabuvad-ritused-content">
          <div className="saabuvad-ritused-main">
            <div className="saabuvad-ritused-branding">
              <span className="saabuvad-ritused-text08">MTÜ Teadusklubi</span>
              <span className="saabuvad-ritused-text09">
                Usume, et teostused, mis unistavast ideest lendama lähevad, on
                parimad!
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="saabuvad-ritused-links">
              <div className="saabuvad-ritused-column">
                <span className="saabuvad-ritused-header">Teadusklubi</span>
                <div className="saabuvad-ritused-list">
                  <Link to="/" className="saabuvad-ritused-navlink04">
                    Home
                  </Link>
                  <Link
                    to="/saabuvad-ritused"
                    className="saabuvad-ritused-navlink05"
                  >
                    Saabuvad üritused
                  </Link>
                  <Link to="/projektid" className="saabuvad-ritused-navlink06">
                    Projektid
                  </Link>
                  <Link to="/kontakt" className="saabuvad-ritused-navlink07">
                    <span className="saabuvad-ritused-text10">Kontakt</span>
                    <br></br>
                  </Link>
                </div>
              </div>
              <div className="saabuvad-ritused-column1">
                <span className="saabuvad-ritused-header1">Social</span>
                <div className="saabuvad-ritused-list1">
                  <Link to="/" className="saabuvad-ritused-navlink08">
                    LinkedIn
                  </Link>
                  <Link to="/" className="saabuvad-ritused-navlink09">
                    Instagram
                  </Link>
                  <Link to="/" className="saabuvad-ritused-navlink10">
                    Facebook
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="saabuvad-ritused-bottom">
            <span className="saabuvad-ritused-text12">
              © 2023 MTÜ Teadusklubi - All rights reserved
            </span>
            <a
              href="#saabuv"
              data-role="scroll-top"
              className="saabuvad-ritused-link2 button"
            >
              <img
                alt="image"
                src="/playground_assets/arrow.svg"
                className="saabuvad-ritused-image"
              />
            </a>
          </div>
        </div>
      </div>
      <div>
        <DangerousHTML
          html={`<script>
document.addEventListener("DOMContentLoaded", function() {
  // get the element with the "scroll-top" data-role
  const scrollTopBtn = document.querySelector('[data-role="scroll-top"]');

  // when the element is clicked
  scrollTopBtn.addEventListener("click", function() {
    // smoothly scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });
});
</script>
`}
        ></DangerousHTML>
      </div>
    </div>
  )
}

export default SaabuvadRitused
