import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import SaabuvadRitused from './views/saabuvad-ritused'
import Projektid from './views/projektid'
import Home from './views/home'
import Kontakt from './views/kontakt'

const App = () => {
  return (
    <Router>
      <div>
        <Route component={SaabuvadRitused} exact path="/saabuvad-ritused" />
        <Route component={Projektid} exact path="/projektid" />
        <Route component={Home} exact path="/" />
        <Route component={Kontakt} exact path="/kontakt" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
