import React from 'react'
import { Link } from 'react-router-dom'

import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import TestimonialCard3 from '../components/testimonial-card3'
import BlogPostCard2 from '../components/blog-post-card2'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Teadusklubi</title>
        <meta property="og:title" content="Teadusklubi" />
      </Helmet>
      <section id="TOP" className="home-hero">
        <video
          loop
          muted
          poster="/playground_assets/img_4738-1500h.jpg"
          preload="auto"
          autoPlay
          playsInline
          className="home-video"
        ></video>
        <header data-thq="thq-navbar" className="home-navbar">
          <span className="home-text">MTÜ Teadusklubi</span>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <div className="home-hamburger">
              <img
                alt="image"
                src="/playground_assets/hamburger.svg"
                className="home-icon"
              />
            </div>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="home-nav"
            >
              <div className="home-container1">
                <span className="home-text01">MTÜ Teaudsklubi</span>
                <div data-thq="thq-close-menu" className="home-menu-close">
                  <svg viewBox="0 0 1024 1024" className="home-icon1">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="home-nav1"
              >
                <Link to="/" className="home-navlink button">
                  Home
                </Link>
                <Link to="/saabuvad-ritused" className="home-navlink01 button">
                  Saabuvad üritused
                </Link>
                <Link to="/projektid" className="home-navlink02 button">
                  Projektid
                </Link>
                <Link to="/kontakt" className="home-navlink03 button">
                  Kontakt
                </Link>
              </nav>
            </div>
            <div className="home-icon-group">
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon3">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon5">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon7">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
        <div className="home-hero-content">
          <div className="home-header-container">
            <div className="home-header">
              <h1 className="home-heading">
                <span className="home-text02">Nooretelt-Noortele</span>
                <span className="home-text03">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br className="home-text04"></br>
              </h1>
            </div>
            <p className="home-caption">
              Inspireerime noori oma visioone elluviima. 
            </p>
          </div>
          <Link to="/projektid" className="home-navlink04 button">
            <span>
              <span>Teadusklubi projektid</span>
              <br></br>
            </span>
          </Link>
        </div>
      </section>
      <section className="home-note">
        <h2 className="home-caption1">
          {' '}
          Inspireerivad edulood. Teaduse populariseerimine, ettevõtluse
          kasvatamine. Uued teadmised elektroonikas, mehhaanikas, disainis,
          ettevõtluses.
        </h2>
      </section>
      <section className="home-statistics">
        <div className="home-content">
          <div className="home-stat">
            <h3 className="home-header1">25</h3>
            <span className="home-caption2">aktiivset liiget</span>
          </div>
          <div className="home-stat1">
            <h3 className="home-header2">TalTech</h3>
            <span className="home-caption3">tudengite algatus</span>
          </div>
          <div className="home-stat2">
            <h3 className="home-header3">10</h3>
            <span className="home-caption4">toetavat ettevõtet</span>
          </div>
          <div className="home-stat3">
            <h3 className="home-header4">4</h3>
            <span className="home-caption5">edukat projekti</span>
          </div>
        </div>
      </section>
      <section className="home-saabuvad-ritused">
        <div className="home-header-container1">
          <div className="home-header5">
            <h2 className="home-heading1">Saabuvad üritused</h2>
            <span className="home-text08">
              <span>
                Ray Foil first launch x
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>Wambola hooajaavamine</span>
              <br></br>
            </span>
            <span className="home-text13">26. mai @ Noblessner</span>
          </div>
          <div className="home-button">
            <Link to="/saabuvad-ritused" className="home-button1 button">
              <span>Lisainfo</span>
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/playground_assets/ray-kanaarid-oras-4325-1500w.jpg"
          className="home-image"
        />
      </section>
      <section className="home-objectives">
        <span className="home-text15">Eesmärgid</span>
        <div className="home-content1">
          <div className="home-objectives-list">
            <div className="home-objective objective">
              <h3 className="home-text16">Ettevõtlikus</h3>
              <p className="home-text17">
                Teadusklubi eesmärk on noortesse süstida ettevõtlikust. Läbi
                jätkusuutlike projektide saadakse esimene kogemus, kuidas oma
                visioone elluviia.
              </p>
            </div>
            <div className="objective home-objective1">
              <h3 className="home-text18">Teadmised</h3>
              <p className="home-text19">
                Läbi erinevate inseneeria- ja teadusprojektide omandavad noored
                uusi teadmisi väga erinevates valdkondades. 
              </p>
            </div>
            <div className="objective home-objective2">
              <h3 className="home-text20">Kogemused</h3>
              <p className="home-text21">
                Läbi reaalsete ja praktiliste projektide omandatakse eluks
                vajalikud kogemused. Teadusklubi liikmed paistavad silma
                tööjõuturul ja loovad ise uusi töökohti.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="home-testimonial">
        <h1 className="home-text22">Pädev juhatus</h1>
        <span className="home-text23">
          Teadusklubi juhatusse kuulub kolm pädevat inseneri ja ettevõtjat.
        </span>
        <div className="home-container2">
          <TestimonialCard3
            quote="TalTech ehitiste projekteerimise ja arhitektuuri tudeng. Ärimudelite ja digitaalsete oskuste mentor. Osalenud mitmetel hackatonidel ja omab poodiumi kohti äriideede konkurssidel."
            picture_src="/playground_assets/oras-03749-200h.jpg"
            rootClassName="rootClassName"
          ></TestimonialCard3>
          <TestimonialCard3
            name="Andres Nöps"
            quote="TalTech küberfüüsikalistesüsteemide vilistlane. Endine Solaride elektroonika tiimijuht ja Bercman Technologies AS arendusjuht. Mentor ja töötubade läbiviija."
            picture_src="/playground_assets/oras-03970-200w.jpg"
            rootClassName="rootClassName"
          ></TestimonialCard3>
          <TestimonialCard3
            name="Teodor Undrits"
            quote="TalTech tootearenduse ja robootika tudeng. Mitmed aastaid käinud Ameerikas raamatuid müümas. Saab iga inimesega läbi ja oskab nende nõrkused põõrata tugevusteks."
            picture_src="/playground_assets/oras-03917-200w.jpg"
            rootClassName="rootClassName"
          ></TestimonialCard3>
        </div>
      </div>
      <div className="home-blog">
        <div className="home-container3">
          <Link to="/projektid" className="home-navlink05">
            <BlogPostCard2
              image_src="/playground_assets/ray-kanaarid-oras-4325-1500w.jpg"
              description="Teadusklubi noored on oma ülesandeks võtnud maailma kompaktseima eFoili arendamise. Jõutud on TRL 5 prototüübini, mis tähendab peaaegu tootmiskõlubulikku toodet."
              profile_src="https://images.unsplash.com/photo-1611232658409-0d98127f237f?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHxwb3J0cmFpdCUyMHdvbWFufGVufDB8fHx8MTYyNjQ1MDU4MQ&amp;ixlib=rb-1.2.1&amp;h=1200"
              rootClassName="rootClassName3"
              className="home-component3"
            ></BlogPostCard2>
          </Link>
        </div>
        <div className="home-container4">
          <Link to="/projektid" className="home-navlink06">
            <BlogPostCard2
              title="Cleveron Challenge"
              image_src="/playground_assets/dsc_0088-1500w.jpg"
              description="Mitteformaalse Teadusklubi liikmed ehitasid Cleveron Challlengesi raames Eesti kiireima elektrilise jalgratta. Võistlust kahjuks ei võidetud, kuid omandati palju uusi teadmisi ja kogemusi."
              profile_src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDExfHxwb3J0cmFpdCUyMHdvbWFufGVufDB8fHx8MTYyNjQ1MDU4MQ&amp;ixlib=rb-1.2.1&amp;h=1200"
              rootClassName="rootClassName"
              className="home-component4"
            ></BlogPostCard2>
          </Link>
        </div>
        <Link to="/projektid" className="home-navlink07">
          <BlogPostCard2
            title="Robotexi võistlused"
            image_src="/playground_assets/static-img.aripaev.ee-1500w.jpeg"
            description="Teadusklubis on palju robootikahuvilisi. Teadusklubi liikmed on osalenud mitmel korral erinevatel Robotexi võistlustel ja saavutanud väga häid tulemusi."
            profile_src="https://images.unsplash.com/photo-1531123897727-8f129e1688ce?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE0fHxwb3J0cmFpdCUyMHdvbWFufGVufDB8fHx8MTYyNjQ1MDU4MQ&amp;ixlib=rb-1.2.1&amp;h=1200"
            rootClassName="rootClassName2"
            className="home-component5"
          ></BlogPostCard2>
        </Link>
      </div>
      <section className="home-create">
        <div className="home-content2">
          <div className="home-header6">
            <h2 className="home-heading2">
              <span>Noor! Sul on visoon?</span>
              <br></br>
              <span>Võta ühendust!</span>
            </h2>
          </div>
          <Link to="/kontakt" className="home-navlink08 button">
            <span>
              <span>Alusta juba täna!</span>
              <br></br>
            </span>
          </Link>
        </div>
      </section>
      <div className="home-footer">
        <div className="home-content3">
          <div className="home-main">
            <div className="home-branding">
              <span className="home-text30">MTÜ Teadusklubi</span>
              <span className="home-text31">
                Usume, et teostused, mis unistavast ideest lendama lähevad, on
                parimad!
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-links">
              <div className="home-column">
                <span className="home-header7">Teadusklubi</span>
                <div className="home-list">
                  <Link to="/" className="home-navlink09">
                    Home
                  </Link>
                  <Link to="/saabuvad-ritused" className="home-navlink10">
                    Saabuvad üritused
                  </Link>
                  <Link to="/projektid" className="home-navlink11">
                    Projektid
                  </Link>
                  <Link to="/kontakt" className="home-navlink12">
                    <span className="home-text32">Kontakt</span>
                    <br></br>
                  </Link>
                </div>
              </div>
              <div className="home-column1">
                <span className="home-header8">Social</span>
                <div className="home-list1">
                  <a
                    href="https://www.linkedin.com/company/teadusklubi/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link"
                  >
                    LinkedIn
                  </a>
                  <a
                    href="https://www.instagram.com/rayfoil.surf/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link1"
                  >
                    Instagram
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=100090970377822"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link2"
                  >
                    Facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="home-bottom">
            <span className="home-text34">
              © 2023 MTÜ Teadusklubi - All rights reserved
            </span>
            <a href="#TOP" data-role="scroll-top" className="home-link3 button">
              <img
                alt="image"
                src="/playground_assets/arrow.svg"
                className="home-image1"
              />
            </a>
          </div>
        </div>
      </div>
      <div>
        <DangerousHTML
          html={`<script>
document.addEventListener("DOMContentLoaded", function() {
  // get the element with the "scroll-top" data-role
  const scrollTopBtn = document.querySelector('[data-role="scroll-top"]');

  // when the element is clicked
  scrollTopBtn.addEventListener("click", function() {
    // smoothly scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });
});
</script>
`}
        ></DangerousHTML>
      </div>
    </div>
  )
}

export default Home
